<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Category') }}</label>
          <v-select
            :value="categoryId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :reduce="(val) => val.id"
            :clearable="false"
            label="name"
            input-id="traitCategoryId"
            @input="(val) => $emit('update:categoryId', val)"
            placeholder="Select Category"/>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="ml-auto mb-md-0 mb-2"
        >
          <div class="d-flex align-items-center justify-content-end mt-2">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                :value="searchQuery"
                @input="(val) => $emit('update:searchQuery', val)"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </b-input-group>
          </div>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import AlertService from '@/common/alert.service'

export default {
  props: {
    searchQuery: {
      type: String,
      default: null,
    },
    categoryId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    loadCategories() {
      this.$store.dispatch('traitCategoryManage/fetchAllList')
        .then(response => {
          if (response.statusCode === 200) {
            this.categories = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
  created() {
    this.loadCategories();
  },
}
</script>
